import {
  format,
  intervalToDuration,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  parseISO,
  formatDistanceToNowStrict,
} from 'date-fns';

/**
 * ATTENTION:
 * If you are using these functions in a server-rendered component, you need to make sure that the client has rendered before using them.
 * You need to either use the hasClientRendered hook or put your date in a useEffect
 * Otherwise this will result in a mismatch between the server and client rendered dates
 * In production you will see a Minified React error #418, #423 or #425
 **/

const MS_IN_A_SECOND = 1000;
export const dateFromSeconds = (seconds: number): Date => new Date(seconds * MS_IN_A_SECOND);

export function getRelativeDate(datetime: string): string {
  const date = new Date(datetime);
  if (isToday(date)) {
    return 'today';
  }
  if (isYesterday(date)) {
    return 'yesterday';
  }
  if (isThisWeek(date)) {
    return 'this week';
  }
  if (isThisMonth(date)) {
    return 'this month';
  }

  return `on ${format(date, 'MMMM d, yyyy')}`;
}

export function formatDate(datetime: string): string {
  const date = new Date(datetime);
  return format(date, 'MMMM d, yyyy');
}

export function formatDateAndTime(datetime: string, longDate = false): string {
  const date = new Date(datetime);
  const timeString = format(date, 'h:mm a');
  if (isToday(date)) {
    return `Today at ${timeString}`;
  }
  if (isYesterday(date)) {
    return `Yesterday at ${timeString}`;
  }
  return `${longDate ? formatDate(datetime) : date.toLocaleDateString()} at ${timeString}`;
}

export function getDuration(startedAt: string, endedAt: string): string {
  const { days, hours, minutes } = intervalToDuration({
    start: new Date(startedAt),
    end: new Date(endedAt),
  });
  return `${days ? `${days}d` : ''} ${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`;
}

export function getDurationInMs(startedTime: number): number {
  return Date.now() - startedTime;
}

const YEAR_MONTH_DATE_FORMAT = 'MMM dd yyyy';
const YEAR_MONTH_FORMAT = 'MMM yyyy';
const YEAR_FORMAT = 'yyyy';
const YEAR_DATE_FORMAT = 'dd yyyy';
const MONTH_DATE_FORMAT = 'MMM dd';
const DATE_FORMAT = 'dd';

export function formatDateRange(startedAt: string, endedAt: string): string {
  // when year, month and date are equal
  if (
    format(parseISO(startedAt), YEAR_MONTH_DATE_FORMAT) ===
    format(parseISO(endedAt), YEAR_MONTH_DATE_FORMAT)
  ) {
    if (isThisYear(parseISO(startedAt))) {
      return format(parseISO(startedAt), MONTH_DATE_FORMAT);
    }
    return format(parseISO(startedAt), YEAR_MONTH_DATE_FORMAT);
  }
  // when year and month are equal
  if (
    format(parseISO(startedAt), YEAR_MONTH_FORMAT) === format(parseISO(endedAt), YEAR_MONTH_FORMAT)
  ) {
    if (isThisYear(parseISO(startedAt))) {
      return `${format(parseISO(startedAt), MONTH_DATE_FORMAT)} - ${format(
        parseISO(endedAt),
        DATE_FORMAT,
      )}`;
    }
    return `${format(parseISO(startedAt), MONTH_DATE_FORMAT)} - ${format(
      parseISO(endedAt),
      YEAR_DATE_FORMAT,
    )}`;
  }
  // when only year is equal
  if (format(parseISO(startedAt), YEAR_FORMAT) === format(parseISO(endedAt), YEAR_FORMAT)) {
    if (isThisYear(parseISO(startedAt))) {
      return `${format(parseISO(startedAt), MONTH_DATE_FORMAT)} - ${format(
        parseISO(endedAt),
        MONTH_DATE_FORMAT,
      )}`;
    }
    return `${format(parseISO(startedAt), MONTH_DATE_FORMAT)} - ${format(
      parseISO(endedAt),
      YEAR_MONTH_DATE_FORMAT,
    )}`;
  }

  return `${format(parseISO(startedAt), YEAR_MONTH_DATE_FORMAT)} - ${format(
    parseISO(endedAt),
    YEAR_MONTH_DATE_FORMAT,
  )}`;
}

export const getRelativeTimeAgo = (pastDate: Date): string =>
  formatDistanceToNowStrict(pastDate, { addSuffix: true });
